var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('list-layout',{ref:"govGoods",attrs:{"filter-form":_vm.filterForm,"thead":_vm.thead,"on-fetch":_vm.getList,"on-edit":_vm.onEdit},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('goods-list-filter',{attrs:{"is-gov":"","filterForm":_vm.filterForm,"uploadFilter":_vm.ok}})]},proxy:true},{key:"item_image",fn:function(ref){
var row = ref.row;
return [(row.data_type === 0)?[_c('list-image',{attrs:{"fit":"cover","width":40,"border-radius":"50%","src":row.image}})]:[(row.res[0])?_c('list-image',{attrs:{"fit":"cover","width":40,"border-radius":"50%","src":row.res[0].thumbnail || row.res[0].url}}):_c('list-image',{attrs:{"fit":"cover","width":40,"border-radius":"50%","src":""}})]]}},{key:"item_status_text",fn:function(ref){
var row = ref.row;
return [_c('ListStatusText',{attrs:{"text":row.status_text,"type":row.status_text === '销售中'
            ? 'success'
            : row.status_text === '待上架'
            ? 'warning'
            : 'info'}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }