/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 09:59:47
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-26 17:26:47
 */

import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";


// 商品列表数据
export const getShopList = data => {
  return api({
    url: '/admin/shop/goods/index',
    method: 'post',
    data
  });
};
// 商品排序
export const updateListSort = data => {
  return api({
    url: '/admin/shop/goods/updateSort',
    method: 'post',
    data
  });
};
// 商品分类数据
export const getCategory = data => {
  return api({
    url: '/admin/wx_open/wx_store_category/all',
    method: 'post',
    data
  });
};
// 软删除
export const softDelete = data => {
  return api({
    url: '/admin/shop/goods/softDelete',
    method: 'post',
    data
  });
};
// 删除
export const deleteRow = data => {
  return api({
    url: '/admin/shop/goods/delete',
    method: 'post',
    data
  });
};
// 商品分类数据
export const putBack = data => {
  return api({
    url: '/admin/shop/goods/putBack',
    method: 'post',
    data
  });
};
// 商品列表导出
export const exportGoodsList = (data) => {
  return fetchFile({
    url: "/admin/shop/goods/exportIndex",
    method: "post",
    data,
    download: true,
  });
};
// 修改商品状态
export const changeStatus = (data) => {
  return api({
    url: '/admin/shop/goods/changeStatus',
    method: 'post',
    data
  });
};
