<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="true"
    :onExport="isGov?null:onExport"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="templateForm"
      label-width="80px"
      size="medium"
      class="filter-from"
    >
      <div class="filter-item">
        <el-form-item label="商品名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入内容"></el-input>
        </el-form-item>

        <el-form-item v-if="!isGov" label="商品分类：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择">
            <el-option
              v-for="item in categoryOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-else label="商品分类：" prop="category_name">
        <el-select v-model="form.category_name" placeholder="请选择">
            <el-option
              v-for="item in categoryOption"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品状态：" prop="status">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item v-if="filterForm.data_type !== undefined" label="数据类型：" prop="data_type">
          <el-select v-model="form.data_type" placeholder="请选择">
            <el-option
              v-for="item in dataTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格区间：" prop="min_price">
          <div class="price-box">
            <el-input v-model="form.min_price" placeholder="最低价"></el-input>
            <div>-</div>
            <el-form-item prop="max_price">
              <el-input
                v-model="form.max_price"
                placeholder="最高价"
              ></el-input>
            </el-form-item>
          </div>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="创建时间："
          start-prop="start_time"
          :start-time.sync="form.start_time"
          end-prop="end_time"
          :end-time.sync="form.end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { getCategory,exportGoodsList } from "../../api/shop-list";
import { getGovShopCategories } from "@/modules/gov/api/goods-library";

export default {
  name: "GoodsListFilter",
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    isGov: Boolean,
    exportApi: Function
  },
  data() {
    return {
      rules: {},
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      // 商品状态选项
      statusOptions: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 0,
          label: "销售中",
        },
        {
          value: 1,
          label: "待上架",
        },
        {
          value: 2,
          label: "已下架",
        },
      ],
      // 商品类型选项
      dataTypeOptions: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 0,
          label: "微信小商店商品",
        },
        {
          value: 1,
          label: "商城商品",
        },
      ],
      // 分类
      categoryOption: [],
    };
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  created() {
    this.getCategoryList();
  },
  methods: {
    onExport() {
      const postData = { ...this.form };
      if (this.exportApi) return this.exportApi(postData)
      return exportGoodsList(postData);
    },
    //重置表单
    resetForm() {
      this.$refs["templateForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    // 分类列表
    getCategoryList() {
      if (this.isGov) {
        getGovShopCategories().then(res => {
          this.categoryOption = res.data;
        }).catch(() => {
        })
      } else {
        getCategory().then((res) => {
          this.categoryOption = res.data;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.price-box {
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin: 0 5px;
  }
}
</style>
