<template>
  <div>
    <list-layout ref="govGoods" :filter-form="filterForm" :thead="thead"
                 :on-fetch="getList" :on-edit="onEdit"
    >
      <template #filter>
        <goods-list-filter is-gov :filterForm="filterForm" :uploadFilter="ok" />
      </template>
      <template slot="item_image" slot-scope="{ row }">
        <template v-if="row.data_type === 0">
          <list-image
              fit="cover"
              :width="40"
              border-radius="50%"
              :src="row.image"
          />
        </template>

        <template v-else>
          <list-image
              v-if="row.res[0]"
              fit="cover"
              :width="40"
              border-radius="50%"
              :src="row.res[0].thumbnail || row.res[0].url"
          />
          <list-image
              v-else
              fit="cover"
              :width="40"
              border-radius="50%"
              src=""
          />
        </template>
      </template>
      <template slot="item_status_text" slot-scope="{ row }">
        <ListStatusText
            :text="row.status_text"
            :type="
            row.status_text === '销售中'
              ? 'success'
              : row.status_text === '待上架'
              ? 'warning'
              : 'info'
          "
        ></ListStatusText>
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import { getGovGoods } from "@/modules/gov/api/goods-library";
import GoodsListFilter from "@/modules/shop/components/ShopList/ListFilter";
import ListStatusText from "@/base/components/List/ListStatusText";
import ListImage from "@/base/components/List/ListImage";

export default {
  components: {ListImage, ListStatusText, GoodsListFilter, ListLayout},
  data() {
    return {
      filterForm: {
        tab: "normal",
        name: "", // 商品名
        data_type: -1, // 商品类型
        status: -1, // 商品状态
        order_by: "", // 排序字段
        is_desc: 1, // 正序还是倒序
        min_price: "", // 最低价
        max_price: "", // 最高价
        category_id: "", // 分类id
        category_name: '', // 分类名称
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        page_size: 15, //每页多少条数据
        project_id: this.$store.getters.tenant.id || ''
      },
      thead: [
        { label: "ID", prop: "id", width: 100 },
        {label: "所属商协会", prop: "project_name", minWidth: 100},
        { label: "商品图片", prop: "image", minWidth: 100, type: "slot" },
        { label: "商品名称", prop: "name", minWidth: 200 },
        {
          label: "商品分类",
          prop: "category_name",
          minWidth: 120,
        },
        {
          label: "价格",
          prop: "price",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "库存",
          prop: "stocks",
          minWidth: 120,
        },
        {
          label: "销量",
          prop: "orders_count",
          minWidth: 120,
        },
        {
          label: "商品状态",
          prop: "status_text",
          minWidth: 200,
          type: "slot",
        },
        {
          label: "数据类型",
          prop: "data_text",
          minWidth: 200,
        },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 200,
          sortable: true,
        },
      ]
    }
  },
  methods: {
    onEdit(row) {

    },
    getList(q) {
      return getGovGoods(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 };
      this.$refs.govGoods.getList(pageData)
    },
  },
}
</script>

<style scoped>

</style>
